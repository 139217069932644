export const ScAddressLink = () => import('../../node_modules/@evince/site/lib/packages/AddressLink.vue' /* webpackChunkName: "components/sc-address-link" */).then(c => wrapFunctional(c.default || c))
export const ScBrowserList = () => import('../../node_modules/@evince/site/lib/packages/BrowserList.vue' /* webpackChunkName: "components/sc-browser-list" */).then(c => wrapFunctional(c.default || c))
export const ScBusinessHours = () => import('../../node_modules/@evince/site/lib/packages/BusinessHours.vue' /* webpackChunkName: "components/sc-business-hours" */).then(c => wrapFunctional(c.default || c))
export const ScCookieBar = () => import('../../node_modules/@evince/site/lib/packages/CookieBar.vue' /* webpackChunkName: "components/sc-cookie-bar" */).then(c => wrapFunctional(c.default || c))
export const ScDirectionsLink = () => import('../../node_modules/@evince/site/lib/packages/DirectionsLink.vue' /* webpackChunkName: "components/sc-directions-link" */).then(c => wrapFunctional(c.default || c))
export const ScDynamicForm = () => import('../../node_modules/@evince/site/lib/packages/DynamicForm.vue' /* webpackChunkName: "components/sc-dynamic-form" */).then(c => wrapFunctional(c.default || c))
export const ScEmailLink = () => import('../../node_modules/@evince/site/lib/packages/EmailLink.vue' /* webpackChunkName: "components/sc-email-link" */).then(c => wrapFunctional(c.default || c))
export const ScFaxLink = () => import('../../node_modules/@evince/site/lib/packages/FaxLink.vue' /* webpackChunkName: "components/sc-fax-link" */).then(c => wrapFunctional(c.default || c))
export const ScLangSwitcher = () => import('../../node_modules/@evince/site/lib/packages/LangSwitcher.vue' /* webpackChunkName: "components/sc-lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const ScMainNav = () => import('../../node_modules/@evince/site/lib/packages/MainNav.vue' /* webpackChunkName: "components/sc-main-nav" */).then(c => wrapFunctional(c.default || c))
export const ScMobileLink = () => import('../../node_modules/@evince/site/lib/packages/MobileLink.vue' /* webpackChunkName: "components/sc-mobile-link" */).then(c => wrapFunctional(c.default || c))
export const ScMobileMenu = () => import('../../node_modules/@evince/site/lib/packages/MobileMenu.vue' /* webpackChunkName: "components/sc-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const ScNewsletterForm = () => import('../../node_modules/@evince/site/lib/packages/NewsletterForm.vue' /* webpackChunkName: "components/sc-newsletter-form" */).then(c => wrapFunctional(c.default || c))
export const ScPhoneLink = () => import('../../node_modules/@evince/site/lib/packages/PhoneLink.vue' /* webpackChunkName: "components/sc-phone-link" */).then(c => wrapFunctional(c.default || c))
export const ScSocialIcons = () => import('../../node_modules/@evince/site/lib/packages/SocialIcons.vue' /* webpackChunkName: "components/sc-social-icons" */).then(c => wrapFunctional(c.default || c))
export const ScViberLink = () => import('../../node_modules/@evince/site/lib/packages/ViberLink.vue' /* webpackChunkName: "components/sc-viber-link" */).then(c => wrapFunctional(c.default || c))
export const ScWhatsappLink = () => import('../../node_modules/@evince/site/lib/packages/WhatsappLink.vue' /* webpackChunkName: "components/sc-whatsapp-link" */).then(c => wrapFunctional(c.default || c))
export const ScContactButton = () => import('../../node_modules/@evince/site/lib/packages/contact/Button.vue' /* webpackChunkName: "components/sc-contact-button" */).then(c => wrapFunctional(c.default || c))
export const ScContactDetails = () => import('../../node_modules/@evince/site/lib/packages/contact/Details.vue' /* webpackChunkName: "components/sc-contact-details" */).then(c => wrapFunctional(c.default || c))
export const ScContactForm = () => import('../../node_modules/@evince/site/lib/packages/contact/Form.vue' /* webpackChunkName: "components/sc-contact-form" */).then(c => wrapFunctional(c.default || c))
export const PageContact = () => import('../../node_modules/@evince/site/lib/templates/contact.vue' /* webpackChunkName: "components/page-contact" */).then(c => wrapFunctional(c.default || c))
export const PageRequest = () => import('../../node_modules/@evince/site/lib/templates/request.vue' /* webpackChunkName: "components/page-request" */).then(c => wrapFunctional(c.default || c))
export const EshopSearchInput = () => import('../../node_modules/@evince/eshop/lib/packages/SearchInput.vue' /* webpackChunkName: "components/eshop-search-input" */).then(c => wrapFunctional(c.default || c))
export const PageEshopProducts = () => import('../../node_modules/@evince/eshop/lib/templates/Products.vue' /* webpackChunkName: "components/page-eshop-products" */).then(c => wrapFunctional(c.default || c))
export const ScMutationForm = () => import('../../node_modules/@evince/core/lib/components/MutationForm.vue' /* webpackChunkName: "components/sc-mutation-form" */).then(c => wrapFunctional(c.default || c))
export const ScCountrySelect = () => import('../../node_modules/@evince/core/lib/components/country/Select.vue' /* webpackChunkName: "components/sc-country-select" */).then(c => wrapFunctional(c.default || c))
export const ScLanguageSelect = () => import('../../node_modules/@evince/core/lib/components/language/Select.vue' /* webpackChunkName: "components/sc-language-select" */).then(c => wrapFunctional(c.default || c))
export const ErrorDetails = () => import('../../node_modules/@evince/core/lib/packages/ErrorDetails.vue' /* webpackChunkName: "components/error-details" */).then(c => wrapFunctional(c.default || c))
export const PwaInstall = () => import('../../node_modules/@evince/core/lib/packages/PwaInstall.vue' /* webpackChunkName: "components/pwa-install" */).then(c => wrapFunctional(c.default || c))
export const PwaPrompt = () => import('../../node_modules/@evince/core/lib/packages/PwaPrompt.vue' /* webpackChunkName: "components/pwa-prompt" */).then(c => wrapFunctional(c.default || c))
export const StateLabel = () => import('../../node_modules/@evince/core/lib/packages/StateLabel.vue' /* webpackChunkName: "components/state-label" */).then(c => wrapFunctional(c.default || c))
export const GraphqlMutation = () => import('../../node_modules/@evince/core/lib/packages/graphql/Mutation.vue' /* webpackChunkName: "components/graphql-mutation" */).then(c => wrapFunctional(c.default || c))
export const GraphqlQuery = () => import('../../node_modules/@evince/core/lib/packages/graphql/Query.vue' /* webpackChunkName: "components/graphql-query" */).then(c => wrapFunctional(c.default || c))
export const BrandLogos = () => import('../../components/BrandLogos.vue' /* webpackChunkName: "components/brand-logos" */).then(c => wrapFunctional(c.default || c))
export const HeaderGraphic = () => import('../../components/HeaderGraphic.vue' /* webpackChunkName: "components/header-graphic" */).then(c => wrapFunctional(c.default || c))
export const IconAlert = () => import('../../components/IconAlert.vue' /* webpackChunkName: "components/icon-alert" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const NotificationPopup = () => import('../../components/NotificationPopup.vue' /* webpackChunkName: "components/notification-popup" */).then(c => wrapFunctional(c.default || c))
export const OffersLink = () => import('../../components/OffersLink.vue' /* webpackChunkName: "components/offers-link" */).then(c => wrapFunctional(c.default || c))
export const PhoneButton = () => import('../../components/PhoneButton.vue' /* webpackChunkName: "components/phone-button" */).then(c => wrapFunctional(c.default || c))
export const ProductCountry = () => import('../../components/ProductCountry.vue' /* webpackChunkName: "components/product-country" */).then(c => wrapFunctional(c.default || c))
export const SearchInput = () => import('../../components/SearchInput.vue' /* webpackChunkName: "components/search-input" */).then(c => wrapFunctional(c.default || c))
export const StayHome = () => import('../../components/StayHome.vue' /* webpackChunkName: "components/stay-home" */).then(c => wrapFunctional(c.default || c))
export const OfferCard = () => import('../../components/offers/OfferCard.vue' /* webpackChunkName: "components/offer-card" */).then(c => wrapFunctional(c.default || c))
export const OffersBanner = () => import('../../components/offers/OffersBanner.vue' /* webpackChunkName: "components/offers-banner" */).then(c => wrapFunctional(c.default || c))
export const OffersDrawer = () => import('../../components/offers/OffersDrawer.vue' /* webpackChunkName: "components/offers-drawer" */).then(c => wrapFunctional(c.default || c))
export const PromoBanner = () => import('../../components/offers/PromoBanner.vue' /* webpackChunkName: "components/promo-banner" */).then(c => wrapFunctional(c.default || c))
export const Copyright = () => import('../../components/layout/Copyright.vue' /* webpackChunkName: "components/copyright" */).then(c => wrapFunctional(c.default || c))
export const FooterColumn = () => import('../../components/layout/FooterColumn.vue' /* webpackChunkName: "components/footer-column" */).then(c => wrapFunctional(c.default || c))
export const FooterColumns = () => import('../../components/layout/FooterColumns.vue' /* webpackChunkName: "components/footer-columns" */).then(c => wrapFunctional(c.default || c))
export const FooterSubscribe = () => import('../../components/layout/FooterSubscribe.vue' /* webpackChunkName: "components/footer-subscribe" */).then(c => wrapFunctional(c.default || c))
export const HomeHeader = () => import('../../components/layout/HomeHeader.vue' /* webpackChunkName: "components/home-header" */).then(c => wrapFunctional(c.default || c))
export const ProductsHeader = () => import('../../components/layout/ProductsHeader.vue' /* webpackChunkName: "components/products-header" */).then(c => wrapFunctional(c.default || c))
export const TopBar = () => import('../../components/layout/TopBar.vue' /* webpackChunkName: "components/top-bar" */).then(c => wrapFunctional(c.default || c))
export const AboutSection = () => import('../../components/sections/AboutSection.vue' /* webpackChunkName: "components/about-section" */).then(c => wrapFunctional(c.default || c))
export const ApplicationsSection = () => import('../../components/sections/ApplicationsSection.vue' /* webpackChunkName: "components/applications-section" */).then(c => wrapFunctional(c.default || c))
export const BrandsSection = () => import('../../components/sections/BrandsSection.vue' /* webpackChunkName: "components/brands-section" */).then(c => wrapFunctional(c.default || c))
export const HomeCategories = () => import('../../components/sections/HomeCategories.vue' /* webpackChunkName: "components/home-categories" */).then(c => wrapFunctional(c.default || c))
export const OffersSection = () => import('../../components/sections/OffersSection.vue' /* webpackChunkName: "components/offers-section" */).then(c => wrapFunctional(c.default || c))
export const ServicesSection = () => import('../../components/sections/ServicesSection.vue' /* webpackChunkName: "components/services-section" */).then(c => wrapFunctional(c.default || c))
export const CategoriesCarousel = () => import('../../components/products/CategoriesCarousel.vue' /* webpackChunkName: "components/categories-carousel" */).then(c => wrapFunctional(c.default || c))
export const CategoryCard = () => import('../../components/products/CategoryCard.vue' /* webpackChunkName: "components/category-card" */).then(c => wrapFunctional(c.default || c))
export const LinkedProducts = () => import('../../components/products/LinkedProducts.vue' /* webpackChunkName: "components/linked-products" */).then(c => wrapFunctional(c.default || c))
export const MinimalCard = () => import('../../components/products/MinimalCard.vue' /* webpackChunkName: "components/minimal-card" */).then(c => wrapFunctional(c.default || c))
export const OffersCarousel = () => import('../../components/products/OffersCarousel.vue' /* webpackChunkName: "components/offers-carousel" */).then(c => wrapFunctional(c.default || c))
export const PriceOptions = () => import('../../components/products/PriceOptions.vue' /* webpackChunkName: "components/price-options" */).then(c => wrapFunctional(c.default || c))
export const ProductBrochures = () => import('../../components/products/ProductBrochures.vue' /* webpackChunkName: "components/product-brochures" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/products/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductDesktop = () => import('../../components/products/ProductDesktop.vue' /* webpackChunkName: "components/product-desktop" */).then(c => wrapFunctional(c.default || c))
export const ProductDetails = () => import('../../components/products/ProductDetails.vue' /* webpackChunkName: "components/product-details" */).then(c => wrapFunctional(c.default || c))
export const ProductGallery = () => import('../../components/products/ProductGallery.vue' /* webpackChunkName: "components/product-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductManufacturer = () => import('../../components/products/ProductManufacturer.vue' /* webpackChunkName: "components/product-manufacturer" */).then(c => wrapFunctional(c.default || c))
export const ProductMeasurements = () => import('../../components/products/ProductMeasurements.vue' /* webpackChunkName: "components/product-measurements" */).then(c => wrapFunctional(c.default || c))
export const ProductMobile = () => import('../../components/products/ProductMobile.vue' /* webpackChunkName: "components/product-mobile" */).then(c => wrapFunctional(c.default || c))
export const ProductPrice = () => import('../../components/products/ProductPrice.vue' /* webpackChunkName: "components/product-price" */).then(c => wrapFunctional(c.default || c))
export const ProductProperties = () => import('../../components/products/ProductProperties.vue' /* webpackChunkName: "components/product-properties" */).then(c => wrapFunctional(c.default || c))
export const ProductStatus = () => import('../../components/products/ProductStatus.vue' /* webpackChunkName: "components/product-status" */).then(c => wrapFunctional(c.default || c))
export const ProductVariantsDesktop = () => import('../../components/products/ProductVariantsDesktop.vue' /* webpackChunkName: "components/product-variants-desktop" */).then(c => wrapFunctional(c.default || c))
export const ProductVariantsMobile = () => import('../../components/products/ProductVariantsMobile.vue' /* webpackChunkName: "components/product-variants-mobile" */).then(c => wrapFunctional(c.default || c))
export const ProductVerticalCard = () => import('../../components/products/ProductVerticalCard.vue' /* webpackChunkName: "components/product-vertical-card" */).then(c => wrapFunctional(c.default || c))
export const ProductsSearch = () => import('../../components/products/ProductsSearch.vue' /* webpackChunkName: "components/products-search" */).then(c => wrapFunctional(c.default || c))
export const RelatedProducts = () => import('../../components/products/RelatedProducts.vue' /* webpackChunkName: "components/related-products" */).then(c => wrapFunctional(c.default || c))
export const ServiceList = () => import('../../components/services/ServiceList.vue' /* webpackChunkName: "components/service-list" */).then(c => wrapFunctional(c.default || c))
export const ServiceTabs = () => import('../../components/services/ServiceTabs.vue' /* webpackChunkName: "components/service-tabs" */).then(c => wrapFunctional(c.default || c))
export const TractorService = () => import('../../components/services/TractorService.vue' /* webpackChunkName: "components/tractor-service" */).then(c => wrapFunctional(c.default || c))
export const ApplicationCard = () => import('../../components/applications/ApplicationCard.vue' /* webpackChunkName: "components/application-card" */).then(c => wrapFunctional(c.default || c))
export const ApplicationCardLoading = () => import('../../components/applications/ApplicationCardLoading.vue' /* webpackChunkName: "components/application-card-loading" */).then(c => wrapFunctional(c.default || c))
export const ApplicationsHeader = () => import('../../components/applications/ApplicationsHeader.vue' /* webpackChunkName: "components/applications-header" */).then(c => wrapFunctional(c.default || c))
export const CollectionExtendedCard = () => import('../../components/collections/CollectionExtendedCard.vue' /* webpackChunkName: "components/collection-extended-card" */).then(c => wrapFunctional(c.default || c))
export const CollectionProducts = () => import('../../components/collections/CollectionProducts.vue' /* webpackChunkName: "components/collection-products" */).then(c => wrapFunctional(c.default || c))
export const CollectionsList = () => import('../../components/collections/CollectionsList.vue' /* webpackChunkName: "components/collections-list" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
