export default async (ctx, inject) => {
  const options = {"el":{"icons":true,"email":"info@paouris.gr","address":"47o χλμ. Ε.Ο. Αθηνών - Λαμίας, Aυλώνας Αττικής","geoAddress":"Παούρης | Τρακτέρ - Γεωργικά μηχανήματα, Avlonas","phone":["+30 22950 41140"],"mobile":"+30 6973 217 928","viber":["+30 6973 217 928","+30 6974 776 855"],"whatsapp":["+30 6973 217 928","+30 6974 776 855"],"businessHours":[{"days":"Δευτέρα - Παρασκευή","hours":"09:00 - 17:00"},{"days":"Σάββατο","hours":"09:00 - 14:00"},{"days":"Κυριακή","notes":"(κατόπιν ραντεβού)","hours":"10:00 - 14:00"}]},"en":{"icons":true,"email":"info@paouris.gr","address":"47th km National Road Athens - Lamia Avlon, Attica 19011","geoAddress":"Paouris Tractors | Anlonas","phone":["+30 22950 41140"],"mobile":"+30 6973 217 928","viber":["+30 6973 217 928","+30 6974 776 855"],"whatsapp":["+30 6973 217 928","+30 6974 776 855"],"businessHours":[{"days":"Monday - Friday","hours":"09:00 - 17:00"},{"days":"Saturday","hours":"09:00 - 14:00"},{"days":"Sunday","notes":"(by appointment)","hours":"10:00 - 14:00"}]}}
  const attrs = Object.values(options)[0]

  const contact = {
    ...options,
    get details() {
      return this[ctx.app.i18n.vm.locale] || {}
    }
  }

  Object.keys(attrs).forEach(key => {
    Object.defineProperty(contact, key, {
      get() {
        return this.details[key]
      }
    })
  })

  inject('contact', contact)
}
